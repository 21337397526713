<template>
  <div class="container mx-auto my-8 space-y-6">
    <div class="flex justify-between">
      <div class="flex space-x-2">
        <base-button
          v-for="tab in tabs"
          :key="tab"
          class="btn text"
          :class="{
            outline: selectedTab === tab,
            'outline outline-muted': selectedTab !== tab,
          }"
          @click="selectedTab = tab"
        >
          {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
        </base-button>
      </div>
      <base-button
        @click.prevent="$router.push('/ghostwriting/dashboard')"
        class="btn text outline inline reverse"
      >
        <inline-svg src="/img/icons/back.svg" />
        <span class="text-secondary">Back</span>
      </base-button>
    </div>
    <notepad :clientId="clientId" :cannotSchedulePosts="cannotSchedulePosts" v-if="selectedTab === 'notepad'" />

    <div class="space-y-5" v-else>
      <div v-bind:key="tweetToDisplay.id" v-for="tweetToDisplay in tweetsToDisplay">
        <div class="card tweet dark-mode-bg shadow-lg">
          <div class="card-header flex justify-between">
            <div class="card-title flex justify-between pr-5px">
              <div
                class="card-time"
                :set="(postingTime = tweetToDisplay.postingTimeData(userProfile.timezone))"
              >
                <p v-if="!tweetToDisplay.time">
                  {{
                    tweetToDisplay.ghostwritingStatus.charAt(0).toUpperCase() +
                    tweetToDisplay.ghostwritingStatus.slice(1)
                  }}
                </p>
                <p v-else-if="tweetToDisplay.time.isAfter(momentNow)">
                  Starts on {{ postingTime.day }} at {{ postingTime.time }}
                </p>
                <p v-else>Started on {{ postingTime.day }} at {{ postingTime.time }}</p>
              </div>
              <tooltip v-if="selectedTab === 'pending'" content="Delete Post">
                <button
                  data-cy="queue-delete-post"
                  type="button"
                  @click="deletePost(tweetToDisplay)"
                  class="btn icon transparent delete"
                >
                  <div>
                    <inline-svg src="/img/icons/delete.svg"></inline-svg>
                  </div>
                </button>
              </tooltip>
            </div>
          </div>
          <div class="card-body container-fluid">
            <div
              class="row whitespace-pre-wrap break-words"
              v-html="formatTweet(tweetToDisplay.tweets[0].status)"
            ></div>
          </div>
          <div v-if="tweetToDisplay.ghostwritingRefusal" class="card-error">
            Refusal Message - {{ tweetToDisplay.ghostwritingRefusal }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Notepad from '@/components/Notepad';
  import { mapGetters, mapState } from 'vuex';
  import { formatTweet } from '@/util/formatTweet';
  import moment from 'moment';
  import 'moment-timezone';
  import { store } from '../store';
  import SwalModalMixinVue from './Mixins/SwalModalMixin.vue';


  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['writerGhostwritingThreads']),
      momentNow() {
        return moment.tz(this.userProfile.timezone).toDate();
      },
      allTweets() {
        return this.writerGhostwritingThreads
          ? this.writerGhostwritingThreads.filter(
              (thread) => thread.user.id === this.clientId && thread.scheduled === false
            )
          : null;
      },
      tweetsToDisplay() {
        return this.allTweets && this.selectedTab !== 'notepad'
          ? this.allTweets.filter((thread) => thread.ghostwritingStatus === this.selectedTab)
          : null;
      },
    },
    async mounted() {
      if (!this.writerGhostwritingThreads) {
        store.dispatch('fetchWriterGhostwritingThreads');
      }

      this.updateClientInfoIfItsValid();
    },
    data() {
      return {
        tabs: ['notepad', 'scheduled', 'pending', 'rejected'],
        selectedTab: 'notepad',
        clientId: null,
        cannotSchedulePosts: false,
      };
    },
    components: {
      Notepad,
    },
    methods: {
      deletePost(post) {
        this.swalModal({
          title: 'Skip',
          html: `Are you sure you want to delete this post?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, go ahead',
          preConfirm: async () => {
            await post.deleteFromFirestore(this.currentUser, this.userProfile.uid);
            this.$notify({
              type: 'success',
              message: 'Post deleted succesfully!',
            });
          }
        });
      },
      formatTweet(tweet) {
        return formatTweet(tweet);
      },
      updateClientInfoIfItsValid() {
        const client = this.userProfile.additionalAccounts.find(
          (user) => user.profileInfo.screen_name === this.$route.params.username
        );
        if (client) {
          this.clientId = client.uid;
          this.cannotSchedulePosts = client.cannotSchedulePosts;
        } else {
          this.$router.push('/');
        }
      },
    },
    watch: {
      $route() {
        // When going from '/ghostwriting/client/user1' to '/ghostwriting/client/user2' the component does not re-render so we need this
        this.updateClientInfoIfItsValid();
      },
    },
    mixins: [SwalModalMixinVue],
  };
</script>

<style lang="scss" scoped>
  .pr-5px {
    padding-right: 5px;
  }
</style>
