<template>
  <div class="space-y-2">
    <div class="card tweet p-5 md:flex md:justify-between select-none">
      <!-- TODO: Please update this message -->
      <p>Only the selected text can be queued</p>
    </div>

    <textarea
      class="w-full form-control border-4 h-75vh"
      placeholder="Type something..."
      @click="updateSelectedText"
      @select="updateSelectedText"
      v-click-outside="updateSelectedText"
      @keyup.ctrl.65="updateSelectedText"
      @input="selectedText = ''"
      v-model="notepadContent"
    ></textarea>
    <div class="flex space-x-2">
      <base-button
        @click="savePost(false)"
        type="primary"
        size="sm"
        class="custom-button"
        :loading="submitting"
        :disabled="!selectedText || !isTweetValid"
        >Queue for review</base-button
      >
      <base-button
        v-if="!cannotSchedulePosts"
        @click="savePost(true)"
        type="primary"
        size="sm"
        class="custom-button"
        :loading="submitting"
        :disabled="!selectedText || !isTweetValid"
        >Schedule</base-button
      >
    </div>
    <span v-if="!isTweetValid" class="text-badge-error"
      >The selected text exceeds 280 characters</span
    >
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import SwalModalMixin from '../views/Mixins/SwalModalMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { DraftThread } from '../models/DraftThread';
  import { Thread } from '../models/Thread';
  import lodash from 'lodash';
  import dao from '@/dao';

  export default {
    props: {
      clientId: {
        type: String,
        default: null,
      },
      cannotSchedulePosts: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedText: '',
        notepadContent: '',
        saveNotepadInterval: null,
        submitting: false,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule']),
      isTweetValid() {
        return countTweetLength(this.selectedText) <= 280;
      },
    },
    beforeDestroy() {
      clearInterval(this.saveNotepadInterval);
    },
    mounted() {
      this.startSavingIntervalAndFillNotepad();
    },
    methods: {
      updateSelectedText() {
        this.selectedText = window.getSelection().toString().trim();
      },
      startSavingIntervalAndFillNotepad() {
        // Every 10 seconds we save the content of the notepad in the database
        this.saveNotepadInterval = setInterval(() => {
          dao.userProfile.updateGhostwritingClientsData(
            this.userProfile.uid,
            this.clientId,
            this.notepadContent,
            'notepadContent'
          );
        }, 10000);

        const notepadLocallyStoredContent = localStorage.getItem(`notepad.${this.clientId}`);
        const notepadDatabaseStoredContent = lodash.get(
          this.userProfile,
          `ghostwritingClientsData.${this.clientId}.notepadContent`
        );

        if (notepadLocallyStoredContent) {
          this.notepadContent = notepadLocallyStoredContent;
        }
        if (notepadDatabaseStoredContent) {
          this.notepadContent = notepadDatabaseStoredContent;
        }
      },
      async savePost(shouldAddToQueue) {
        this.submitting = true;

        try {
          const tweets = [
            {
              count: 0,
              media: null,
              published: false,
              status: this.selectedText,
            },
          ];

          const time = this.schedule.getNextTimeSlot();

          const thread = shouldAddToQueue
            ? Thread.newThread({
                time,
                tweets,
                user: this.clientId,
                source: 'ghostwriting',
                writer: this.userProfile.uid,
                ghostwritingStatus: 'scheduled',
              })
            : new DraftThread({
                tweets,
                user: this.clientId,
                source: 'ghostwriting',
                writer: this.userProfile.uid,
                ghostwritingStatus: 'pending',
              });

          await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.userProfile.timezone,
            this.$eventStore,
            shouldAddToQueue
          );

          this.$notify({ type: 'success', message: 'Tweet queued successfully!' });
        } catch(error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: `An error ocurred while trying to queue the tweet.`,
            type: 'error',
          });
        } finally {
          this.submitting = false;
          this.selectedText = '';
        }
      },
    },
    watch: {
      notepadContent(val) {
        localStorage.setItem(`notepad.${this.clientId}`, val);
      },
      clientId(val) {
        if (val) {
          this.startSavingIntervalAndFillNotepad();
        }
      },
    },
    mixins: [SwalModalMixin],
  };
</script>

<style lang="scss">
  .h-75vh {
    height: 75vh !important;
  }
</style>
